<script>
  import {
    GridContainer,
    GridColumn,
    ButtonPrimary,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import css from './style.scss'

  const cN = classNames.bind(css)

  export let title = undefined
  export let subTitle = undefined
  export let button
  export let withTopMargin = undefined
  export let withBottomMargin = undefined
  export let titleTag = undefined
</script>

<WidgetHeader {title} {subTitle} {titleTag} withMargin={withTopMargin} />
<GridContainer>
  <GridColumn width={0} widthMiddle={4} />
  <GridColumn width={12} widthMiddle={4} class={cN('cta')}>
    <ButtonPrimary
      width={ButtonPrimary.WIDTHS.WIDE}
      backdrop={ButtonPrimary.BACKDROPS.LIGHT}
      rounded
      isLink
      href={button.href}>
      {button.text}
    </ButtonPrimary>
  </GridColumn>
  <GridColumn width={0} widthMiddle={4} />
</GridContainer>
<WidgetFooter withMargin={withBottomMargin} />
